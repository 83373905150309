<template>
    <!-- Login-->
        <p class="card-text mb-2 pt-2"><b> Enter your new password </b></p>
        <form class="auth-login-form mt-2" action="#" @submit.prevent="createChangePassword" v-if="!isResetSuccessful">
          <div class="_dashboard_content" >


            <div class="mb-1">
                <div class="d-flex justify-content-between">
                    <label class="form-label" for="login-password" :class="fv.password.msg?'text-danger':''">
                        Current Password
                        <span v-if="fv.password.msg" > | {{fv.password.msg}}</span>
                    </label>

                </div>
                <div class="input-group input-group-merge form-password-toggle">
                    <input class="form-control form-control-merge" required id="fv_password"
                     :type="fv.password.showPassword?'text':'password'" v-model="fv.password.val"
                     :class="fv.password.msg?'border-danger':''"
                     placeholder="Enter Password" @keyup="validate.validateFormData(fv,'password')"
                     aria-describedby="login-password" min="8" max="32" tabindex="2" />
                     <span class="input-group-text cursor-pointer" @click="togglePasswordVisibility">
                        <i class="bi bi-eye"></i>
                    </span>
                </div>
            </div>

            <div class="mb-1">
                <div class="d-flex justify-content-between">
                    <label class="form-label" for="login-password" :class="fv.newPassword.msg?'text-danger':''">
                        New Password
                        <span v-if="fv.newPassword.msg" > | {{fv.newPassword.msg}}</span>
                    </label>

                </div>
                <div class="input-group input-group-merge form-password-toggle">
                    <input class="form-control form-control-merge" required id="fv_newPassword"
                     :type="fv.newPassword.showPassword?'text':'password'" v-model="fv.newPassword.val"
                     :class="fv.newPassword.msg?'border-danger':''"
                     placeholder="Enter Password" @keyup="validate.validateFormData(fv,'password')"
                     aria-describedby="login-password" min="8" max="32" tabindex="2" />
                     <span class="input-group-text cursor-pointer" @click="fv.newPassword.showPassword = !fv.newPassword.showPassword">
                        <i class="bi bi-eye"></i>
                    </span>
                </div>
            </div>




            <div class="col-12" v-if="fv.reqFeedback.status">

                <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
            </div>

            <div >
                <base-button btnColor="primary" class="w-100 mt-2" tabindex="4" v-if="fv.showSubmit.val">
                    Change password
                </base-button>
                <base-spinner  v-else></base-spinner>
            </div>

        </div>
    </form>
    <!-- End Login-->

    <base-card-response  cardType="success" v-if="isResetSuccessful">

        <template #header>Password reset was successful. </template>
        <template #default>
            <p>
                You have successfully changed your password.
            </p>

        </template>

    </base-card-response>
</template>

<script>
import { ref,reactive,inject } from 'vue'
import validators from '@/hooks/validation'

export default{

  name: 'CreateAuthChangePassword',
  setup() {

    // get the validators
    const validate = validators();

    // get the axios object
    const axios = inject('axios');  // inject axios

    //get the urls
    const coreUrl = inject('coreUrl');


    const reqError  = ref("Loading login form....");




     // create variables for the values to be submitted
    const fv = reactive({
        password : {status:false, val:"", msg:false, showPassword: false, validate:["required",
                                                                                      {option:"textMin",val:10},
                                                                                      {option:"textMax",val:64}
                                                                                     ]},
        newPassword : {status:false, val:"", msg:false, showPassword: false, validate:["required",
                                                                                      {option:"textMin",val:10},
                                                                                      {option:"textMax",val:64}
                                                                                     ]},
        reqFeedback : {status:false, val:"", msg:false},
        showSubmit : {status:false, val:true}
    })


    // toggle password visibility
    function togglePasswordVisibility(){
        fv.password.showPassword = !fv.password.showPassword;
    }

    // get the current status of the created account
    const isResetSuccessful = ref(false);

    // create the account using async
    async function createChangePassword(){

        fv.showSubmit.val = false;

        // load the form data , and append all the necessary fields
        let formData = new FormData();

        formData.append("fv_password", fv.password.val);
        formData.append("fv_newPassword", fv.newPassword.val);

        // post the data using axios
        try{

            const response = await axios.put(
                coreUrl.backendUrl+'create_password_change',
                formData,
                {
                    withCredentials: true,
                    headers:{
                       "Content-Type": "multipart/form-data"
                    }
                }
            );

            // If successful, get the resppnse data
            const responseData = response.data;

            if(responseData.status){
                // update is account created status
                isResetSuccessful.value = true;
                fv.reqFeedback.status = false;

                fv.showSubmit.val = true;
            }else{

                for(const key in responseData.message){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = responseData.message[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = responseData.message['req_msg'];
                fv.reqFeedback.val = "danger";
                fv.showSubmit.status = false;
                fv.showSubmit.val = true;
            }




        }catch(error){


            // load the error to the appropriate fiel

            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = "We had issues processing your request";
            fv.reqFeedback.val = "danger";
            fv.showSubmit.status = false;
            fv.showSubmit.val = true;

            // end load the error to the appropriate field

        }

     }



    return {
        fv,
        validate,
        reqError,
        togglePasswordVisibility,
        createChangePassword,
        isResetSuccessful
     }
  }
}
</script>
