<template>


    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h2 class="content-header-title float-start mb-0">Manage Credentials</h2>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Update your email or password</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-body">
                <!-- account setting page -->
                <section id="page-account-settings">
                    <div class="row">
                        <!-- left menu section -->
                        <div class="col-md-3 mb-2 mb-md-0">
                            <ul class="nav nav-pills flex-column nav-left">
                                <!-- Update password -->
                                <li class="nav-item">
                                    <a class="nav-link active" id="post-job-pill-options" data-bs-toggle="pill" href="#post-job-options" aria-expanded="false">
                                        <i class="bi bi-lock-fill bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">Update password</span>
                                    </a>
                                </li>
                                <!-- Update Email -->
                                <li class="nav-item" v-if="showEmailChange">
                                    <a class="nav-link" id="post-job-pill-jd" data-bs-toggle="pill" href="#post-job-jd" aria-expanded="true">
                                        <i class="bi bi-envelope-fill bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">Update email</span>
                                    </a>
                                </li>

                            </ul>
                        </div>
                        <!--/ left menu section -->

                        <!-- right content section -->
                        <div class="col-md-9">
                            <div class="card">
                                <div class="card-body">
                                    <div class="tab-content">

                                        <!-- post-job-pill-option -->
                                        <div class="tab-pane active" id="post-job-options" role="tabpanel" aria-labelledby="post-job-pill-options" aria-expanded="false">

                                            <!-- form -->
                                            <create-auth-change-password>
                                            </create-auth-change-password>
                                            <!--/ form -->

                                        </div>
                                        <!--/ post-job-pill-option -->

                                        <!-- post-job-pill-jd -->
                                        <div role="tabpanel" class="tab-pane" id="post-job-jd" aria-labelledby="post-job-pill-jd" aria-expanded="true">


                                            <create-auth-change-email v-if="showEmailChange">
                                            </create-auth-change-email>



                                        </div>
                                        <!--/ post-job-pill-jd -->




                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--/ right content section -->
                    </div>
                </section>
                <!-- / account setting page -->

            </div>
        </div>
    </div>
    <!-- END: Content-->


</template>

<script>
// import the core functions from vuex
import { ref } from 'vue';
import { useStore } from 'vuex';


import CreateAuthChangeEmail from '@/components/forms/auth/CreateAuthChangeEmail.vue';
import CreateAuthChangePassword from '@/components/forms/auth/CreateAuthChangePassword.vue';

export default{
    components:{
        CreateAuthChangeEmail,
        CreateAuthChangePassword
    },
    setup(){
        // get the global vuex store values
        const store = useStore();

        // use the dispatch function to call an action that calls a mutation
        store.dispatch("globals/executeSetNavMenu",true);

        const showEmailChange = ref(false);

        showEmailChange.value = store.getters['auth/getAuthCredentials'].accountType == "organization"?true:false;

        return {
            showEmailChange
        }
    }
}


</script>

<style scoped>
.bs-icon-small{
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem;
}

.bs-icon-tiny{
    height: 0.5rem;
    width: 0.5rem;
    font-size: 0.5rem;
    margin-right: 1.1rem;
    flex-shrink: 0;}


</style>
